import './style.css';
import javascriptLogo from './javascript.svg';
import viteLogo from '/vite.svg';
import { getOpenAIResponse, generateImage, transcribeAudio } from './openaiService';

document.addEventListener('DOMContentLoaded', () => {
  // Render the app HTML
  const appHTML = `
    <div id="app-content">
      <h1 style="color: #ffff00;">Hello! Meet Wissen</h1>

      <div>
        <h2 style="color: #ffff00;">Chat with Wissen</h2>
        <div class="input-container">
          <input id="prompt" placeholder="Enter your prompt" />
          <button id="micButton" class="mic-button"><i class="fa fa-microphone"></i></button>
        </div>
        <button id="fetchResponse">Get Response</button>
        <div id="dialogContainer"></div>
      </div>

      <div>
        <h2 style="color: #ffff00;">Generate Image with Wissen!</h2>
        <input id="imagePrompt" placeholder="Enter your image prompt" />
        <button id="fetchImage">Generate Image</button>
        <div id="imageContainer"></div>
        <div id="spinner" class="spinner"></div>
      </div>

      <footer><h2 style="color: #0000ff;">Powered by</h2></footer>

      <a href="https://vitejs.dev" target="_blank">
        <img src="${viteLogo}" class="logo" alt="Vite logo" />
      </a>
      <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank">
        <img src="${javascriptLogo}" class="logo vanilla" alt="JavaScript logo" />
      </a>
    </div>
  `;

  // Attach rendered HTML to the DOM
  document.querySelector('#app').innerHTML = appHTML;

  // Attach event listeners for chat and image generation
  setupEventListeners();
});

// Function to set up event listeners
function setupEventListeners() {
  // Chat with OpenAI
  document.querySelector('#fetchResponse').addEventListener('click', fetchResponse);
  document.querySelector('#prompt').addEventListener('keypress', (event) => handleEnterKey(event, fetchResponse));

  // Generate image with OpenAI
  document.querySelector('#fetchImage').addEventListener('click', fetchImage);
  document.querySelector('#imagePrompt').addEventListener('keypress', (event) => handleEnterKey(event, fetchImage));

  // Microphone feature for speech-to-text
  document.querySelector('#micButton').addEventListener('click', handleMicrophone);
}

// Function to handle "Enter" key
function handleEnterKey(event, callback) {
  if (event.key === 'Enter') {
    callback();
  }
}

// Function to fetch the response from OpenAI and display it
async function fetchResponse() {
  const prompt = document.querySelector('#prompt').value;
  const dialogContainer = document.querySelector('#dialogContainer');

  if (!prompt.trim()) {
    displayError(dialogContainer, 'Please enter a prompt.');
    return;
  }

  try {
    const response = await getOpenAIResponse(prompt);
    const responseElement = document.createElement('p');
    simulateTyping(response, responseElement);
    dialogContainer.appendChild(responseElement);
    clearInput('#prompt');
  } catch (error) {
    displayError(dialogContainer, 'Error fetching data from OpenAI API.');
  }
}

// Function to fetch a generated image from OpenAI
async function fetchImage() {
  const prompt = document.querySelector('#imagePrompt').value;
  const imageContainer = document.querySelector('#imageContainer');
  const spinner = document.querySelector('#spinner');

  if (!prompt.trim()) {
    displayError(imageContainer, 'Please enter an image prompt.');
    return;
  }

  clearContainer(imageContainer);
  spinner.style.display = 'block';

  try {
    const imageUrl = await generateImage(prompt);
    displayImage(imageUrl, imageContainer);
    clearInput('#imagePrompt');
  } catch (error) {
    displayError(imageContainer, 'Error generating image from OpenAI API.');
  } finally {
    spinner.style.display = 'none';
  }
}

// Function to handle microphone for speech-to-text
async function handleMicrophone() {
  const micButton = document.getElementById('micButton');
  let mediaRecorder;
  let stream;

  if (!navigator.mediaDevices) {
    alert('Microphone access is not supported in this browser.');
    return;
  }

  try {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder = new MediaRecorder(stream);
    const audioChunks = [];

    mediaRecorder.ondataavailable = (event) => audioChunks.push(event.data);
    mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const transcription = await transcribeAudio(audioBlob);
      if (transcription) {
        document.querySelector('#prompt').value = transcription;
      }
      resetMicButton(micButton);
    };

    mediaRecorder.start();
    micButton.style.backgroundColor = 'red'; // Indicate recording is on

    setTimeout(() => stopRecording(mediaRecorder, stream, micButton), 5000); // Auto-stop after 5 seconds
  } catch (error) {
    console.error('Error accessing microphone:', error);
    resetMicButton(micButton);
  }
}

// Helper functions

function stopRecording(mediaRecorder, stream, micButton) {
  mediaRecorder.stop();
  stream.getTracks().forEach((track) => track.stop());
  resetMicButton(micButton);
}

function resetMicButton(micButton) {
  micButton.style.backgroundColor = 'white';
}

function simulateTyping(text, container, delay = 50) {
  let i = 0;
  const interval = setInterval(() => {
    if (i < text.length) {
      container.textContent += text.charAt(i);
      i++;
    } else {
      clearInterval(interval);
    }
  }, delay);
}

function displayImage(url, container) {
  const imgElement = document.createElement('img');
  imgElement.src = url;
  imgElement.alt = 'Generated Image';
  imgElement.className = 'generated-image';
  container.appendChild(imgElement);
}

function displayError(container, message) {
  const errorElement = document.createElement('p');
  errorElement.textContent = message;
  errorElement.style.color = 'red';
  container.appendChild(errorElement);
}

function clearContainer(container) {
  container.innerHTML = '';
}

function clearInput(selector) {
  document.querySelector(selector).value = '';
}

