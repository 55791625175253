import axios from 'axios';

// Create a reusable OpenAI service instance
const openaiService = axios.create({
  baseURL: 'https://api.openai.com/v1',
  headers: {
    'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
    'Content-Type': 'application/json',
  },
});

// Function to get an AI response with retry logic using GPT-4o-latest
export const getOpenAIResponse = async (prompt, maxRetries = 3, userId = 'anonymous') => {
  const requestBody = {
    model: 'chatgpt-4o-latest', // Use GPT-4o-latest
    messages: [
      { role: 'system', content: 'You are an assistant named Wissen you know a lot about psychology and technology and respond only in English.' },
      { role: 'user', content: prompt }
    ],
    max_tokens: 300,
    temperature: 0.5,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0
  };

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      console.log(`Attempt ${attempt}: Requesting response from model 'chatgpt-4o-latest'...`);
      const response = await openaiService.post('/chat/completions', requestBody);

      // Validate the response structure
      const messageContent = response?.data?.choices?.[0]?.message?.content;
      if (messageContent) {
        console.log('AI Response:', messageContent); // Log response to the console
        return messageContent; // Return the content if successful
      }

      throw new Error('Unexpected response structure from OpenAI API'); // Error for invalid structure
    } catch (error) {
      console.error(`Error on attempt ${attempt}:`, error.response?.data || error.message);

      if (attempt === maxRetries) {
        throw new Error('Failed after multiple attempts'); // Throw error if retries are exhausted
      }

      console.log(`Retrying... (${attempt}/${maxRetries})`);
    }
  }
};

// Function to generate an image using DALL-E 3 with improved error handling
export const generateImage = async (prompt, userId = 'anonymous') => {
  const model = 'dall-e-3'; // Explicitly defining the model here
  const requestBody = {
    model: model, // Ensure the correct model is sent in the request
    prompt: prompt,
    n: 1,
    size: '1024x1024'
  };

  try {
    console.log(`Generating image with model: ${model}`);
    const response = await openaiService.post('/images/generations', requestBody);

    // Validate the response structure
    const imageUrl = response?.data?.data?.[0]?.url;
    if (imageUrl) {
      console.log('Generated Image URL:', imageUrl); // Log the image URL to the console
      return imageUrl; // Return the image URL if successful
    }

    throw new Error('Unexpected response structure from OpenAI API'); // Error for invalid structure
  } catch (error) {
    console.error('Error generating image from OpenAI API:', error.response?.data || error.message);
    throw error; // Rethrow error for handling at a higher level
  }
};

// Function to transcribe audio using Whisper-1 with improved error handling
export const transcribeAudio = async (audioBlob, userId = 'anonymous') => {
  const formData = new FormData();
  formData.append('file', audioBlob, 'audio.wav');
  formData.append('model', 'whisper-1');

  try {
    console.log('Transcribing audio with Whisper-1...');
    const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
      headers: {
        'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    // Validate the response structure
    const transcriptionText = response?.data?.text;
    if (transcriptionText) {
      console.log('Transcription:', transcriptionText); // Log the transcription to the console
      return transcriptionText; // Return the transcription if successful
    }

    throw new Error('Unexpected response structure from OpenAI Whisper API'); // Error for invalid structure
  } catch (error) {
    console.error('Error transcribing audio with Whisper-1:', error.response?.data || error.message);
    throw error; // Rethrow error for handling at a higher level
  }
};

